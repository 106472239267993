<template>
  <div
    v-if="imageBase64 !== ''"
    v-viewer
  >
    <b-img
      v-bind="imageConfig"
      fluid
      :src="imageBase64"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    mrData: {
      type: Object,
      default: () => {},
    },
    mrImageKey: {
      type: Number,
      default: () => {},
    },
    hn: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageBase64: '',
      imageConfig: {
        height: 1000,
        width: 700,
        center: true,
      },
    }
  },
  created() {
    if (this.mrData.disable === true) {
      this.imageBase64 = ''
    } else if (this.mrData.nameImage !== undefined) {
      this.$http({
        url: 'api/Image/GetImage',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          Grp: this.mrData.location,
          IMage: this.mrData.nameImage,
          Type: 'OPD',
          hn: parseInt(this.hn, 10),
        },
      }).then(({ data }) => {
        if (data.imageBase64 !== null) {
          this.imageBase64 = `data:image/jpeg;base64, ${data.imageBase64}`
        }
      })
    }
  },
}
</script>

<style>

</style>
