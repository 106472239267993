<template>
  <div
    v-viewer="{movable: false}"
    class="images"
  >
    <img
      v-for="src in ipdImages"
      :key="src"
      v-bind="imageConfig"
      :src="src"
    >
    <b-spinner
      v-show="loadingCheck === true"
      label="Loading..."
    />
    <b-pagination
      v-show="ipdAmount != null"
      v-model="currentPage"
      :total-rows="ipdAmount"
      :per-page="perPage"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      align="left"
      last-text="Last"
    />
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'

Vue.use(VueViewer)
export default {
  components: {
    BPagination,
  },
  props: {
    ipdData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ipdImages: [],
      imageConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 200,
        height: 250,
        class: 'ml-2 mb-1',
        center: true,
      },
      ipdAmount: null,
      currentPage: 1,
      perPage: 21,
      offSet: 0,
      loadingCheck: false,
    }
  },
  watch: {
    currentPage(value) {
      this.offSet = value * 21
      this.ipdImages = []
      this.getImage()
    },
  },
  async created() {
    await this.getImage()
    await this.findIpdImageAmount()
  },
  methods: {
    async findIpdImageAmount() {
      await this.$http({
        url: '/api/Image/ClinicIPDAmount',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          hn: Number(this.ipdData.hn),
          clinic: this.ipdData.clinicDate,
        },
      }).then(({ data }) => {
        this.ipdAmount = data
      })
    },
    async getImage() {
      this.loadingCheck = true
      await this.$http({
        url: '/api/Image/ImageIPD',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          Clinic: this.ipdData.clinicDate,
          Month: 0,
          Offset: this.offSet,
          Year: 0,
          hn: Number(this.ipdData.hn),
        },
      }).then(({ data }) => {
        data.medicalRecord.forEach(element => {
          this.ipdImages.push(`data:image/jpeg;base64, ${element.imageBase64}`)
        })
      })
      this.loadingCheck = false
    },
  },
}
</script>

<style>

</style>
