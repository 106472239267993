<template>
  <div>
    <b-card>
      <v-select
        v-model="typeSelect"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="option"
      />
      <br>
      <div v-if="advanceFilter">
        <h6>ค้นหาขั้นสูง (EMR/MR)</h6>
        <b-row>
          <b-col>
            <h6>ค้นหาคลินิก</h6>
            <v-select
              v-model="clinicSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="clinicList"
              @input="searchByClinic(clinicSelect)"
            />
          </b-col>
          <b-col>
            <h6>ค้นหารายชื่อหมอ</h6>
            <v-select
              v-model="doctorSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="doctorList"
              @input="searchByDocCode(doctorSelect)"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row>
      <b-col
        sm="12"
        md="4"
        lg="2"
      >
        <b-card v-if="checkMobile">
          <b-form-group>
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-card>
        <b-card
          class="overflow-auto"
          style="max-height: 500px;"
        >
          <div v-show="loadingValue === false">
            <app-timeline>
              <app-timeline-item
                v-for="item in opdList"
                :key="item.index"
                class="mt-1 mb-1"
                variant="success"
                @click="GetPatientData(item)"
              >
                <b-button
                  block
                  squared
                  variant="outline-primary"
                >
                  {{ item.logDate }} {{ item.location }}/{{ item.managedBy }}
                </b-button>
              </app-timeline-item>
              <app-timeline-item
                v-for="item in ipdList"
                :key="item.index"
                variant="success"
                @click="GetPatientData(item)"
              >
                <div v-if="item.Clinic !== ''">
                  <b-button
                    block
                    squared
                    variant="outline-primary"
                  >
                    {{ item.Clinic }}
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    block
                    squared
                    variant="outline-primary"
                  >
                    {{ 'ไม่ระบุวัน' }}
                  </b-button>
                </div>
              </app-timeline-item>
              <app-timeline-item
                v-for="item in documentList"
                :key="item.index"
                variant="success"
                @click="GetPatientData(item)"
              >
                <div v-if="item.Clinic !== ''">
                  <b-button
                    block
                    squared
                    variant="outline-primary"
                  >
                    {{ item.logDate }}
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    block
                    squared
                    variant="outline-primary"
                  >
                    {{ 'ไม่ระบุวัน' }}
                  </b-button>
                </div>
              </app-timeline-item>
            </app-timeline>
          </div>
          <div
            v-show="loadingValue === true"
            style="text-align: center;"
          >
            <b-spinner
              label="Spinning"
            />
          </div>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="8"
        lg="10"
      >
        <b-card>
          <div v-if="mrImageKey !== 0">
            <viewerMR
              :key="mrImageKey"
              :mr-data="mrData"
              :hn="hn"
            />
          </div>
          <div v-if="ipdImageKey !== 0">
            <viewerIPD
              :key="ipdImageKey"
              :ipd-data="ipdData"
            />
          </div>
          <div v-if="documentImageKey !== 0">
            <viewerDocument
              :key="documentImageKey"
              :document-data="documentData"
              :hn="hn"
            />
          </div>
          <div v-if="emrKey !== 0">
            <viewerEMR
              :key="emrKey"
              :emr-data="emrData"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import viewerMR from './viewerMR.vue'
import viewerIPD from './viewerIPD.vue'
import viewerEMR from './viewerEMR.vue'
import viewerDocument from './viewerDocument.vue'

Vue.use(VueViewer)

export default {
  components: {
    vSelect,
    AppTimeline,
    AppTimelineItem,
    viewerMR,
    viewerIPD,
    viewerEMR,
    viewerDocument,
  },
  data() {
    return {
      dir: 'ltr',
      option: ['EMR', 'MR', 'IPD'],
      typeSelect: '',
      clinicSelect: '',
      doctorSelect: '',
      advanceFilter: false,
      hn: '',
      clinicList: [],
      opdList: [],
      opdTemp: [],
      ipdList: [],
      ipdTemp: [],
      documentTemp: [],
      documentList: [],
      doctorList: [],
      mrData: {},
      mrImageKey: 0,
      emrKey: 0,
      ipdImageKey: 0,
      documentImageKey: 0,
      ipdData: {},
      documentData: {},
      checkMobile: false,
      checkTablet: false,
      loadingValue: false,
    }
  },
  watch: {
    async typeSelect(value) {
      this.loadingValue = true
      await this.searchData(value)
      if (value !== 'EMR' && value !== 'MR') {
        this.advanceFilter = false
      } else {
        this.advanceFilter = true
      }
      this.mrImageKey = 0
      this.ipdImageKey = 0
      this.documentImageKey = 0
      this.emrKey = 0
      this.loadingValue = false
    },
  },
  created() {
    this.hn = this.$route.params.hn
    if (isMobileOnly) {
      this.checkMobile = true
    }
    if (isTablet) {
      this.checkTablet = true
    }
    this.$http({
      url: `api/HIE/OtherDocType/${this.hn}`,
      method: 'GET',
      headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
    }).then(({ data }) => {
      data.forEach(element => {
        this.option.push(element.typeName)
      })
    })
  },
  methods: {
    searchByClinic(value) {
      this.opdList = [...this.opdTemp]
      if (value) {
        this.opdList = this.opdList.filter(element => element.location === value)
      }
    },
    searchByDocCode(value) {
      this.opdList = [...this.opdTemp]
      if (value) {
        this.opdList = this.opdList.filter(element => element.managedBy === value)
      }
    },
    GetPatientData(value) {
      this.mrImageKey = 0
      this.ipdImageKey = 0
      this.documentImageKey = 0
      if (value.type === 'MR') {
        this.mrData = {
          location: value.location,
          managedBy: value.managedBy,
          type: value.type,
          nameImage: value.nameImage,
        }
        this.mrImageKey = value.index
      } else if (value.type === 'EMR') {
        this.emrData = {
          location: value.location,
          managedBy: value.managedBy,
          type: value.type,
          txn: value.txn,
          hn: this.hn,
        }
        this.emrKey = value.index
      } else if (value.type === 'IPD') {
        this.ipdData = {
          hn: this.hn,
          clinicDate: value.Clinic,
        }
        this.ipdImageKey = value.index
      } else {
        this.documentData = {
          grp: value.type,
          nameImage: value.nameImage,
          type: 'Grp',
          hn: parseInt(this.hn, 10),
        }
        this.documentImageKey = value.index
      }
    },
    async searchData(value) {
      this.opdTemp = []
      this.opdList = []
      this.ipdTemp = []
      this.ipdList = []
      this.documentList = []
      this.documentTemp = []
      if (value === 'EMR' || value === 'MR') {
        let urlLink = ''
        if (value === 'EMR') {
          urlLink = `api/HIE/EMRDoc/${this.hn}`
        } else {
          urlLink = `api/HIE/MRDoc/${this.hn}`
        }
        await this.$http({
          url: urlLink,
          method: 'GET',
        }).then(({ data }) => {
          this.buildOpdData(data, value)
        })
      } else if (value === 'IPD') {
        let ipdCountIndex = 0
        await this.$http({
          url: `api/HIE/IPDDocDate/${this.hn}`,
          method: 'GET',
        }).then(({ data }) => {
          data.forEach(element => {
            ipdCountIndex += 1
            this.ipdTemp.push({
              Clinic: element.clinicDate,
              type: value,
              index: ipdCountIndex,
            })
            this.ipdList = [...this.ipdTemp]
            this.opdList = {}
            this.documentList = {}
          })
        })
      } else {
        let documentCountIndex = 0
        await this.$http({
          url: `api/HIE/OtherDoc?hn=${this.hn}&type=${value}`,
          method: 'GET',
        }).then(({ data }) => {
          data.forEach(element => {
            documentCountIndex += 1
            this.documentTemp.push({
              logDate: element.logDate.substring(0, 10),
              nameImage: element.nameImage,
              type: element.type,
              index: documentCountIndex,
            })
            this.documentList = [...this.documentTemp]
            this.opdList = {}
            this.ipdList = {}
          })
        })
      }
    },
    buildOpdData(data, value) {
      const clinicListTemp = []
      const doctorListTemp = []
      let indexCount = 0
      data.forEach(element => {
        indexCount += 1
        if (value === 'MR') {
          this.opdTemp.push({
            location: element.location,
            logDate: element.logDate.substring(0, 10),
            managedBy: element.managedBy,
            index: indexCount,
            type: value,
            nameImage: element.nameImage,
          })
        } else {
          this.opdTemp.push({
            location: element.location,
            logDate: element.logDate.substring(0, 10),
            managedBy: element.managedBy,
            index: indexCount,
            type: value,
            txn: element.txn,
          })
        }
        if (element.location === '') {
          clinicListTemp.push('ไม่ระบุ')
        } else {
          clinicListTemp.push(element.location)
        }
        doctorListTemp.push(element.managedBy)
      })
      this.opdList = [...this.opdTemp]
      this.clinicList = [...new Set(clinicListTemp)]
      this.doctorList = [...new Set(doctorListTemp)]
      this.ipdList = {}
      this.documentList = {}
    },
  },
}
</script>

<style>

</style>
