<template>
  <div
    v-if="imageBase64 !== ''"
    v-viewer
  >
    <b-img
      v-bind="imageConfig"
      fluid
      :src="imageBase64"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    documentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imageBase64: '',
      imageConfig: {
        height: 1000,
        width: 700,
        center: true,
      },
    }
  },
  created() {
    this.$http({
      url: 'api/Image/GetImage',
      method: 'POST',
      headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
      data: {
        Grp: this.documentData.grp,
        IMage: this.documentData.nameImage,
        Type: this.documentData.type,
        hn: this.documentData.hn,
      },
    }).then(({ data }) => {
      if (data.imageBase64 !== null) {
        this.imageBase64 = `data:image/jpeg;base64, ${data.imageBase64}`
      }
    })
  },
}
</script>

<style>

</style>
