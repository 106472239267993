<template>
  <div>
    <well-baby
      v-if="emrData.location === 'EX5WEL'"
      :key="emrData.txn"
      :hn="emrData.hn"
      :txn="emrData.txn"
    />
    <ob
      v-else-if="emrData.location === 'OB'"
      :key="emrData.txn"
      :hn="emrData.hn"
      :txn="emrData.txn"
    />
    <checkUp
      v-else-if="emrData.location === 'EX5CHK'"
      :key="emrData.txn"
      :hn="emrData.hn"
      :txn="emrData.txn"
    />
    <generals
      v-else
      :key="emrData.txn"
      :hn="emrData.hn"
      :txn="emrData.txn"
    />
  </div>
</template>

<script>
import wellBaby from '@/components/record-viewer/wellBaby.vue'
import ob from '@/components/record-viewer/ob.vue'
import generals from '@/components/record-viewer/general.vue'
import checkUp from '@/components/record-viewer/checkup.vue'

export default {
  components: {
    wellBaby,
    ob,
    generals,
    checkUp,
  },
  props: {
    emrData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style>

</style>
